@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url('assets/images/bg_redcurtain.jpg') !important;
  background-attachment: fixed !important;
  background-size: 100vw 100vh !important;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: 'PT Sans', sans-serif;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #5f5f5f;
  margin: 1em 0;
  padding: 0;
}

.hr {
  background: #e5e5e5; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #f5f5f5 0%,
    #e5e5e5 49%,
    #f5f5f5 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #f5f5f5 0%,
    #e5e5e5 49%,
    #f5f5f5 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #f5f5f5 0%,
    #e5e5e5 49%,
    #f5f5f5 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  margin: 0 10px 30px;
  height: 1px;
}

.chakra-modal__content {
  width: auto !important;
  max-width: 98vw !important;
}

.chakra-modal__body {
  padding-bottom: 24px !important;
}
